export function isUrl(string: string): boolean {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

export function mediaUrl(string: string): string {
  if (isUrl(string)) {
    return string;
  }

  return `${process.env.NEXT_PUBLIC_EDITOR_URL}${string}`;
}

export function fileUrl(string: string): string {
  if (isUrl(string)) {
    return string;
  }

  if (string.includes('fra1.digitaloceanspaces')) return `https://${string}`;
  return `${process.env.NEXT_PUBLIC_EDITOR_URL}${string}.file`;
}
