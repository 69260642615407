import { useMemo } from 'react';
import SimpleReactLightbox from 'simple-react-lightbox';

import ContentImage from 'components/content/ContentImage';
import ContentFiles from 'components/content/ContentFiles';
import ContentAgenda from 'components/content/ContentAgenda';
import { PageItemEventDay } from 'data/Page';

export interface ContentNode {
  type: string;
  attributes?: {
    html?: string;
    src?: string;
    content?: string;
    set?: any[];
  };
}

export function isEmptyContent(content: string) {
  const nodes = JSON.parse(content).data as ContentNode[];
  return (
    nodes.filter(node => {
      if (!node) return false;
      return [
        'text',
        'hr',
        'embed',
        'gallery',
        'table',
        'ul',
        'ol',
        'files',
        'agenda',
      ].includes(node.type);
    }).length === 0
  );
}

interface ContentProps {
  data: string;
  agenda?: PageItemEventDay[];
}

function Content({ data, agenda }: ContentProps) {
  const content = useMemo(() => JSON.parse(data).data as ContentNode[], [data]);
  return (
    <SimpleReactLightbox>
      {content.map((node, key) => {
        if (!node) {
          return null;
        }

        if (node.type === 'text') {
          return (
            <div
              key={key}
              dangerouslySetInnerHTML={{ __html: node.attributes?.html ?? '' }}
            />
          );
        }

        if (node.type === 'hr') {
          return <hr key={key} />;
        }

        if (node.type === 'embed') {
          return (
            <iframe
              key={key}
              title={'Video'}
              src={node.attributes?.src}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          );
        }

        if (node.type === 'gallery') {
          return <ContentImage key={key} images={node.attributes?.set ?? []} />;
        }

        if (node.type === 'table') {
          return (
            <div className="Table__scroll" key={key}>
              <table
                dangerouslySetInnerHTML={{
                  __html: node.attributes?.content ?? '',
                }}
              />
            </div>
          );
        }

        if (node.type === 'ul') {
          return (
            <ul
              key={key}
              dangerouslySetInnerHTML={{
                __html: node.attributes?.content ?? '',
              }}
            />
          );
        }

        if (node.type === 'ol') {
          return (
            <ol
              key={key}
              dangerouslySetInnerHTML={{
                __html: node.attributes?.content ?? '',
              }}
            />
          );
        }

        if (node.type === 'files') {
          return <ContentFiles key={key} files={node.attributes?.set ?? []} />;
        }

        if (node.type === 'agenda' && agenda) {
          return <ContentAgenda key={key} agenda={agenda} />;
        }

        return null;
      })}
    </SimpleReactLightbox>
  );
}

export default Content;
