import { fileUrl } from 'utils/url';

export interface ContentFile {
  path: string;
  type: string;
  name: string;
  size: string;
}

interface ContentFilesProps {
  files: ContentFile[];
}

function ContentFiles({ files }: ContentFilesProps) {
  return (
    <div className="ContentFiles">
      {files.map(({ path, type, name, size }, key) => (
        <a
          href={fileUrl(path)}
          target="_blank"
          rel="noopener noreferrer"
          key={key}
          className={`ContentFiles__item ContentFiles__item--${type}`}
        >
          <div className="ContentFiles__itemName">{name}</div>
          <div className="ContentFiles__itemSize">{size}</div>
        </a>
      ))}
    </div>
  );
}

export default ContentFiles;
