import {
  endOfDay,
  isPast,
  isSameDay,
  isWithinInterval,
  startOfDay,
} from 'date-fns';

import { PageItemEvent, PageItemEventDay } from 'data/Page';
import { parse } from 'utils/date';

export async function getEvents(
  locale: string,
  slug: string,
): Promise<PageItemEventDay[]> {
  const request = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/${locale}/page-events/${slug}`,
  );

  const { events } = (await request.json()) as {
    events: PageItemEventDay[];
  };

  return events;
}

export function getEventStartDate(data: PageItemEvent[]): Date | undefined {
  if (data.length === 0) return undefined;

  const times = data.map(({ date_start_int }) => date_start_int);
  const min = Math.min(...times);

  return parse(min);
}

export function getEventEndDate(data: PageItemEvent[]): Date | undefined {
  if (data.length === 0) return undefined;

  const times = ([] as number[]).concat(
    ...[
      data.map<number>(({ date_start_int }) => date_start_int),
      data
        .filter(e => e.date_end_int !== null)
        .map<number>(({ date_end_int }) => date_end_int ?? 0),
    ],
  );

  const max = Math.max(...times);
  return parse(max);
}

export function getEventsWithDate(
  data: PageItemEvent[],
  date: Date,
): PageItemEvent[] {
  return data.filter(event => {
    const start = parse(event.date_start_int);
    const end = event.date_end_int ? parse(event.date_end_int) : null;

    if (!end) {
      return isSameDay(date, start);
    }

    return isWithinInterval(date, {
      start: startOfDay(start),
      end: endOfDay(end),
    });
  });
}

export function isAllEventsPast(events: PageItemEvent[]): boolean {
  return (
    events.filter(({ date_start_int, date_end_int }) => {
      const start = parse(date_start_int);
      const end = date_end_int ? parse(date_end_int) : null;

      if (!end) return isPast(start);
      return isPast(end);
    }).length === events.length
  );
}
