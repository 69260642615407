import formatDate from 'date-fns/format';
import en from 'date-fns/locale/en-US';
import cs from 'date-fns/locale/cs';
import de from 'date-fns/locale/de';
import fromUnixTime from 'date-fns/fromUnixTime';
import { eachDayOfInterval } from 'date-fns';

function convertTZ(date: Date, tzString: string): Date {
  return new Date(
    date.toLocaleString('en-US', {
      timeZone: tzString,
    }),
  );
}

export function format(date: Date, format: string, locale?: string) {
  let dateLocale = cs;
  if (locale === 'en') {
    dateLocale = en;
  } else if (locale === 'de') {
    dateLocale = de;
  }

  return formatDate(date, format, {
    locale: dateLocale,
  });
}

export function parse(unixTime: number): Date {
  const date = fromUnixTime(unixTime);
  return convertTZ(date, 'UTC');
}

export function getDatesInRange(start: Date, end: Date): Date[] {
  return eachDayOfInterval({ start, end });
}
