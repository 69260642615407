/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isAfter, isSameDay } from 'date-fns';
import { useTranslation } from 'next-i18next';
import fromUnixTime from 'date-fns/fromUnixTime';

import ContentAgendaDay from 'components/content/ContentAgendaDay';
import { PageItemEventDay } from 'data/Page';

interface ContentAgendaProps {
  agenda: PageItemEventDay[];
}

function ContentAgenda({ agenda: events }: ContentAgendaProps) {
  const { t } = useTranslation('common');
  const [pastVisible, setPastVisible] = useState(false);
  const [opened, setOpened] = useState<number | undefined>();

  const toggleDay = useCallback(
    (time: number) => {
      setOpened(time === opened ? undefined : time);
    },
    [opened],
  );

  useEffect(() => {
    if (opened) return;
    for (const { day_int } of events) {
      const date = fromUnixTime(day_int);
      if (isSameDay(new Date(), date)) {
        setOpened(date.getTime());
        return;
      }

      if (isAfter(date, new Date())) {
        setOpened(date.getTime());
        break;
      }
    }
  }, [events]);

  const visibleEvents = useMemo<PageItemEventDay[]>(() => {
    if (pastVisible) return events;
    return events.filter(({ is_past }) => !is_past);
  }, [events, pastVisible]);

  if (events.length === 0) {
    return null;
  }

  return (
    <div className="ContentAgenda">
      {!pastVisible && (
        <button
          type="button"
          className="ContentAgenda__button ContentAgenda__button--older"
          onClick={() => setPastVisible(true)}
          tabIndex={-1}
        >
          {t('AGENDA_OLDER')}
        </button>
      )}
      {visibleEvents.map(({ events, day_int }, index) => {
        const date = fromUnixTime(day_int);
        return (
          <ContentAgendaDay
            key={index}
            date={date}
            events={events}
            opened={opened === date.getTime()}
            onToggleClick={d => toggleDay(d.getTime())}
          />
        );
      })}
    </div>
  );
}

export default ContentAgenda;
