import classNames from 'classnames';
import { isPast, isSameMinute, isWithinInterval } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { PageItemEvent } from 'data/Page';
import { format, parse } from 'utils/date';
import Content, { isEmptyContent } from 'components/content/Content';
import { isUrl } from 'utils/url';

const TIME_MORNING = '00:00';
const TIME_NOON = '23:59';

const LOCATION_ORIGIN = {
  lng: 14.213187,
  lat: 50.780688,
};

interface ContentAgendaEventTimeProps {
  event: PageItemEvent;
}

function ContentAgendaEventTime({ event }: ContentAgendaEventTimeProps) {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  const startTime = useMemo(() => {
    const start = parse(event.date_start_int);
    return format(start, 'HH:mm', locale);
  }, [event.date_start_int, locale]);

  const endTime = useMemo(() => {
    const end = event.date_end_int ? parse(event.date_end_int) : null;
    if (!end) return undefined;
    return format(end, 'HH:mm', locale);
  }, [event.date_end_int, locale]);

  if (!endTime && startTime === TIME_MORNING) {
    return <span>{t('AGENDA_TIME_MORNING')}</span>;
  }

  if (!endTime && startTime !== TIME_MORNING) {
    return <span>{startTime}</span>;
  }

  if (startTime === TIME_MORNING && endTime === TIME_NOON) {
    return <span>{t('AGENDA_TIME_ALL_DAY')}</span>;
  }

  if (startTime === TIME_MORNING && endTime !== TIME_NOON) {
    return (
      <span>
        {t('AGENDA_TIME_MORNING')}&nbsp;&ndash;&nbsp;{endTime}
      </span>
    );
  }

  if (startTime !== TIME_MORNING && endTime === TIME_NOON) {
    return (
      <span>
        {startTime}&nbsp;&ndash;&nbsp;{t('AGENDA_TIME_EVENING')}
      </span>
    );
  }

  return (
    <span>
      {startTime}&nbsp;&ndash;&nbsp;{endTime}
    </span>
  );
}

interface ContentAgendaEventContentProps {
  event: PageItemEvent;
}

function ContentAgendaEventContent({ event }: ContentAgendaEventContentProps) {
  return (
    <>
      <div className="ContentAgenda__eventDescription">{event.description}</div>
      {event.location && !Array.isArray(event.location) && (
        <>
          {event.location.address && isUrl(event.location.address) ? (
            <a
              href={event.location.address}
              className="ContentAgenda__eventLocation"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="ContentAgenda__eventLocationName">
                {event.location.name}
              </span>
            </a>
          ) : (
            <a
              href={`https://www.google.com/maps/dir/${LOCATION_ORIGIN.lat},${LOCATION_ORIGIN.lng}/${event.location.lat},${event.location.lng}`}
              className="ContentAgenda__eventLocation"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="ContentAgenda__eventLocationName">
                {event.location.name}
              </span>
              {event.location.travel && (
                <span className="ContentAgenda__eventLocationDirection">
                  {event.location.travel}
                </span>
              )}
            </a>
          )}
        </>
      )}
    </>
  );
}

interface ContentAgendaEventProps {
  event: PageItemEvent;
  onToggleClick?: (event: PageItemEvent) => void;
  opened?: boolean;
}

function ContentAgendaEvent({
  event,
  opened,
  onToggleClick,
}: ContentAgendaEventProps) {
  const { t } = useTranslation('common');

  const start = useMemo(
    () => parse(event.date_start_int),
    [event.date_start_int],
  );

  const end = useMemo(
    () => (event.date_end_int ? parse(event.date_end_int) : null),
    [event.date_end_int],
  );

  const past = useMemo(() => {
    if (!end) return isPast(start);
    return isPast(end);
  }, [end, start]);

  const now = useMemo(() => {
    if (!end) return isSameMinute(new Date(), start);
    return isWithinInterval(new Date(), { start, end });
  }, [end, start]);

  const toggle = useCallback(() => {
    if (!onToggleClick) return;
    onToggleClick(event);
  }, [event, onToggleClick]);

  const empty = useMemo(() => isEmptyContent(event.content), [event]);

  return (
    <div
      className={classNames('ContentAgenda__event', {
        'ContentAgenda__event--opened': opened,
        'ContentAgenda__event--past': past,
      })}
    >
      <div className="ContentAgenda__eventContainer">
        <div className="ContentAgenda__eventHeader">
          <div className="ContentAgenda__eventTime">
            <ContentAgendaEventTime event={event} />
            {!past && now && (
              <span className="ContentAgenda__eventTimeNow">
                {t('AGENDA_NOW')}
              </span>
            )}
            {past && opened && (
              <span className="ContentAgenda__eventTimeNow ContentAgenda__eventTimeNow--past">
                {t('AGENDA_PAST')}
              </span>
            )}
          </div>
          <div className="ContentAgenda__eventHeaderContent">
            <div className="ContentAgenda__eventTitle">{event.title}</div>
            {(!past || opened) && <ContentAgendaEventContent event={event} />}
          </div>
        </div>
        {(past || !empty) && (
          <button
            type="button"
            className="ContentAgenda__eventInfo"
            onClick={toggle}
          >
            {t('AGENDA_INFO')}
          </button>
        )}
      </div>
      {opened && !empty && (
        <div className="ContentAgenda__eventContent">
          <Content data={event.content} />
        </div>
      )}
    </div>
  );
}

export default ContentAgendaEvent;
