/* eslint-disable @next/next/no-img-element */
import { SRLWrapper, SRLWrapperOptions } from 'simple-react-lightbox';
import classNames from 'classnames';

import { mediaUrl } from '../../utils/url';

const SRLOptions: SRLWrapperOptions = {
  settings: {
    lightboxTransitionSpeed: 0.15,
  },
  caption: {
    showCaption: false,
  },
  buttons: {
    showAutoplayButton: false,
    showDownloadButton: false,
    showThumbnailsButton: false,
    showFullscreenButton: false,
    showNextButton: false,
    showPrevButton: false,
    iconPadding: '8px',
    size: '40px',
  },
  thumbnails: {
    showThumbnails: false,
  },
};

interface ContentImageProps {
  images: string[];
}

function ContentImage({ images }: ContentImageProps) {
  if (images.length === 1) {
    return (
      <SRLWrapper options={SRLOptions}>
        <div className="ContentImage ContentImage--single">
          <img src={mediaUrl(images[0])} alt="" />
        </div>
      </SRLWrapper>
    );
  }

  return (
    <SRLWrapper options={SRLOptions}>
      <div>
        <div
          className={classNames('ContentImage', {
            'ContentImage--two': images.length === 2,
          })}
        >
          {images.map((image, key) => (
            <div key={key} className="ContentImage__item">
              <img src={mediaUrl(image)} alt="" />
            </div>
          ))}
        </div>
      </div>
    </SRLWrapper>
  );
}

export default ContentImage;
