import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { getEventsWithDate } from 'data/Event';
import { PageItemEvent } from 'data/Page';
import { format } from 'utils/date';
import ContentAgendaEvent from 'components/content/ContentAgendaEvent';

interface ContentAgendaDayProps {
  date: Date;
  events: PageItemEvent[];
  onToggleClick: (date: Date) => void;
  opened?: boolean;
}

function ContentAgendaDay({
  date,
  events: data,
  onToggleClick,
  opened,
}: ContentAgendaDayProps) {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const [eventUid, setEventUid] = useState<string | undefined>();

  const events = useMemo(() => getEventsWithDate(data, date), [data, date]);

  const toggle = useCallback(() => {
    if (!onToggleClick) return;
    onToggleClick(date);
  }, [onToggleClick, date]);

  const toggleEvent = useCallback(
    (uid: string) => {
      setEventUid(uid === eventUid ? undefined : uid);
    },
    [eventUid],
  );

  useEffect(() => setEventUid(undefined), [opened]);

  if (events.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames('ContentAgenda__group', {
        'ContentAgenda__group--opened': opened,
      })}
    >
      <div className="ContentAgenda__groupHeader" onClick={toggle}>
        <div className="ContentAgenda__groupDate">
          {format(date, 'dd. MM. yyyy', locale)}
        </div>
        <div className="ContentAgenda__groupTitle">
          {format(date, 'EEEE', locale)}
        </div>
        <div
          className={classNames('ContentAgenda__groupToggle', {
            'ContentAgenda__groupToggle--opened': opened,
            'ContentAgenda__groupToggle--closed': !opened,
          })}
        >
          {opened && t('AGENDA_COLLAPSE')}
          {!opened && t('AGENDA_EXPAND')}
        </div>
      </div>
      {opened && (
        <div className="ContentAgenda__eventList">
          {events.map(event => (
            <ContentAgendaEvent
              key={event.uid}
              event={event}
              opened={event.uid === eventUid}
              onToggleClick={e => toggleEvent(e.uid)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default ContentAgendaDay;
